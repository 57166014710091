import { createTheme } from '@mui/material/styles';
import {red} from "@mui/material/colors";

// A custom theme for this app
export default createTheme({
    palette: {
        mode: 'dark',
        pink: "#f677f7",
        red: red[500],
    }
});
