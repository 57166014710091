import appwrite from "./appwrite";

export const TeamLoader = async ({ params }) => {
    const response = await appwrite.getTeamById(params.id);
    // const mergedResponse = await appwrite.joinTeams(response);
    // get projects for each team
    // get tasks for each project
    // get tags for each project
    return response;
};

export const ProjectLoader = async ({ params }) => {
    const team = params.id;
    const project = params.projectId;

    const response = await appwrite.getSpecificProject(team, project);
    return response;
}
