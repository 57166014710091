import AddProject from '../components/addProject';
import { useSelector } from 'react-redux';
import { Card, Stack, Typography, IconButton, CardHeader, CardContent, Modal, Fade, Backdrop, Box, TextField, Button, CircularProgress } from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import appwrite from '../api/appwrite';
import { useDispatch } from 'react-redux';
import { assignUserProjects } from '../features/projects/projectSlice';
import { useNavigate, Link } from 'react-router-dom';

function DeleteButton({project}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState("");
    const [disabled, setDisabled] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setInput("")
    };
    const handleTextInput = (e) => {
        const value = e.target.value;
        setInput(value)
        if (value === project.name) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    };
    const projectCollection = useSelector(state => state.projects.projectsCollectionId);
    const databaseId = useSelector(state => state.projects.databaseId);
    const dispatch = useDispatch();
    const handleDelete = async() => {
        await appwrite.deleteDocument(databaseId, projectCollection, project.$id)
        setOpen(false);
        dispatch(assignUserProjects());
    }
    
    return (
        <div>
        <IconButton onClick={handleOpen}><TrashIcon/></IconButton>
        <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        >
                <Fade in={open}>
                    <Box sx={style}>
                    <h2>Are You Sure</h2>
                    <p>In order to delete please type your project name below: <i>{project.name}</i></p>
                    <Stack spacing={1}>
                        <TextField autoComplete="off" value={input} onChange={handleTextInput} id="ProjectValue" label="Project Name" />
                        <Button onClick={handleDelete} disabled={disabled} color="error" variant="contained" >Delete</Button>
                    </Stack>

                    </Box>
            </Fade>
        </Modal>
        </div>
    )
}

function ProjectView({projects}) {
    const personalWorkspace = useSelector(state => state.projects.personalWorkspace);
    // const navigate = useNavigate();

    // const handleLink = (id) => {
    //     navigate(`/teams/${personalWorkspace.$id}/project/${id}`)
    // }
    return (
        <div style={{width: "fit-content"}}>
        <Typography variant="h3" color="white">Active Projects</Typography>
        {personalWorkspace.teamId}
        <br />
        <Stack spacing={2}>
        {projects.map((project, i) => (
            <div key={`project-${i}`}>
                <Card sx={{padding: "0 20px"}}>
                    <CardHeader title={project.name} action={
                    <DeleteButton project={project} />
                    } />
                    <CardContent>
                    <Typography variant="p">{project.$id}</Typography>
                    <Button to={`/teams/${personalWorkspace.$id}/project/${project.$id}`} component={Link}>View</Button>
                    </CardContent>
                </Card>
                </div>
            ))
        }
        </Stack>
       </div> 
    )
}

function Home() {
    const projects = useSelector(state => state.projects.projects);
    const projectCollection = useSelector(state => state.projects.projectsCollectionId);
    const databaseId = useSelector(state => state.projects.databaseId);
    return (
        <div>
            <ProjectView projects={projects} />
            <AddProject database={databaseId} collection={projectCollection} />
        </div>
    );
}

export default Home;