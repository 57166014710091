import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css';
import reportWebVitals from './reportWebVitals';
// import modules for routing
import App from './App';
import Teams from './pages/teams'
import Home from './pages/home'
import { Provider } from 'react-redux';
import store from './app/store';
import Login from './pages/login';
import { Navigate } from 'react-router-dom';
import TeamDirect from './pages/teams/id'
import ProjectDirect from './pages/projectDirect'
import { TeamLoader, ProjectLoader } from './api/loaders'
import Error404 from './pages/errors/404'


const router = createBrowserRouter([
  {
      path: '/',
      element: <App />,
      children: [
        {
          path: 'teams',
          element: <Teams />,
      },
      {
          path: '',
          element: <Home />
      },
      {
        path: 'teams/:id',
        element: <TeamDirect />,
        loader: TeamLoader,
        errorElement: <Navigate to="/404" />
      },
      {
        path: 'teams/:id/project/:projectId',
        element: <ProjectDirect />,
        loader: ProjectLoader,
        errorElement: <Navigate to="/404" />
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '*',
    element: <Navigate to="/404" />
  },
  {
    path: '/404',
    element: <Error404 />
  }
  
  
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
