import "./App.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, Link, useNavigate } from "react-router-dom";
import appwrite from "./api/appwrite.js";
import customTheme from "./themes/index";
import {
  styled,
  useTheme,
  ThemeProvider,
  Box,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  GroupIcon,
  MenuItem,
  Menu,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Avatar,
  IconButton,
  Drawer,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Home,
  Info,
  Group,
  MenuOutlined,
} from "@mui/icons-material";
// import { checkLogin } from './features/auth/authActions';
import { getAuth, logout } from "./features/auth/authSlice";
import { assignTeams, assignUserProjects  } from "./features/projects/projectSlice";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function App() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const authUser = useSelector((state) => state.auth.user);
  const authError = useSelector((state) => state.auth.error);
  const projects = useSelector((state) => state.projects.projects);
  const authLoading = useSelector((state) => state.auth.loading);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const authEmail = useSelector((state) => state.auth.email);
  const teams = useSelector((state) => state.projects.teams);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuth());
  }, []);

  useEffect(() => {
    if (!loggedIn && !authLoading) {
      navigate("/login");
    }
    if (loggedIn) {
      // dispatch(getProjects(authEmail));
      appwrite.getTeams(authEmail).then((resp) => {
        appwrite.joinTeams(resp).then((joinedResp) => {
        dispatch(assignTeams(joinedResp));
        dispatch(assignUserProjects())
        });
      });
    }
  }, [authLoading, loggedIn, authEmail, dispatch, navigate]);
  const routes = [
    { name: "My Projects", path: "/", icon: <Home /> },
    { name: "Teams", path: "/teams", icon: <Info /> },
  ];
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function namer(name) {
    try {
      name = name.toUpperCase();
      return {
        children: `${
          name.split(" ").length > 1
            ? name.split(" ")[0][0] + name.split(" ")[1][0]
            : name[0]
        }`,
      };
    } catch (e) {
      return {
        children: null,
      };
    }
  }

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className="App">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: "none" }) }}
                >
                  <MenuOutlined />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  Projo
                </Typography>
              </div>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleAvatarClick}>
                  <Avatar {...namer(authUser?.name)} />
                </IconButton>
                <Menu
                  sx={{ mt: "50px" }}
                  id="popup-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography textAlign="center">My account</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {routes.map((route, index) => (
                <ListItem
                  style={{ color: "white" }}
                  key={index}
                  component={Link}
                  to={route.path}
                  disablePadding
                >
                  <ListItemButton>
                    {/* <Link className="navButton" style={{textDecoration: 'None'}} to={route.path} key={index}> */}
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.name} />
                    {/* </Link> */}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />

            <List>
              {teams.map((team, index) => (
                <ListItem
                  style={{ color: "white" }}
                  key={index}
                  component={Link}
                  to={`/teams/${team.$id}`}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Group />
                    </ListItemIcon>
                    <ListItemText primary={team.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
            <Outlet />
          </Main>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
