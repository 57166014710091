import { Typography, Button, Stack } from '@mui/material'
import '../../styles/error.css'
const { useNavigate } = require("react-router-dom")


export default function Error404() {
    const navigate = useNavigate()

    const onGoBack = () => {
        navigate(-1)
    }

    return (
        <Stack className="content" spacing={1}>
            <Typography className="errorTitle" variant="h1" color="white">404</Typography>
            <Typography variant="p" color="white" className="errorDescription">Page not found</Typography>
            <Button onClick={onGoBack} variant="contained" color="primary">Go Back</Button>
        </Stack>
    )
}