import { Fab, Fade, Modal, Backdrop, Box, TextField, Stack, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import appwrite from '../api/appwrite';
import { useDispatch } from 'react-redux';
import { assignUserProjects} from '../features/projects/projectSlice';

const buttonPlacement = {
    position: 'absolute',
    bottom: '40px',
    right: '40px',
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function AddButton({database, collection}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [loading, setLoading] = useState(false);

    const [projectNameError, setProjectNameError] = useState({value: false, message: ""});
    const [projectDescriptionError, setProjectDescriptionError] = useState({value: false, message: ""});

    const handleNameChange = (e) => {
        setProjectName(e.target.value);
        if (e.target.value.length < 3) {
            setProjectNameError({value: true, message: "Project name must be at least 3 characters long"})
        }
        else {
            setProjectNameError({value: false, message: ""})
        }
    }

    const handleDescriptionChange = (e) => {
        setProjectDescription(e.target.value);
        if (e.target.value.length < 5) {
            setProjectDescriptionError({value: true, message: "Project description must be at least 3 characters long"})
        }
        else {
            setProjectDescriptionError({value: false, message: ""})
        }
    }

    const handleCreation = async() => {
        setLoading(true);
        console.log(!projectNameError.value && !projectDescriptionError.value)
        if (!projectNameError.value && !projectDescriptionError.value) {
            await appwrite.createProject({database, collection}, projectName, projectDescription)
            dispatch(assignUserProjects())
            setLoading(false);
            handleClose();
        }
    }

    const handleClose = () => {
        setOpen(false);
        setProjectName("");
        setProjectDescription("");
        setProjectNameError({value: false, message: ""});
        setProjectDescriptionError({value: false, message: ""});
    }
    return (
        <>
        <Fab style={buttonPlacement} onClick={() => setOpen(true)} color="primary" aria-label="add">
            <AddIcon />
        </Fab>
        <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <h2>New Project</h2>
                    <Stack spacing={1.5}>
                    <TextField error={projectNameError.value} helperText={projectNameError.message} value={projectName} onChange={handleNameChange} id="ProjectName" label="Project Name" />
                    <TextField error={projectDescriptionError.value} helperText={projectDescriptionError.message} value={projectDescription} onChange={handleDescriptionChange} fullWidth minRows={3} multiline id="ProjectDescription" label="Project Description" />
                    <Button onClick={handleCreation}>Create</Button>
                    </Stack>
                    </Box>
            </Fade>
        </Modal>
</>

    );
}