import { Client as Appwrite, Databases, Account, Teams, Query, Functions } from "appwrite";
import { Server } from "../utils/config";

let api = {
  sdk: null,

  provider: () => {
    if (api.sdk) {
      return api.sdk;
    }
    let appwrite = new Appwrite();
    appwrite.setEndpoint(Server.endpoint).setProject(Server.project);
    const account = new Account(appwrite);
    const database = new Databases(appwrite);
    const team = new Teams(appwrite);
    const functions = new Functions(appwrite);

    api.sdk = { account, database, team, functions };
    return { account, database, team, functions };
  },

  //   createAccount: (email, password, name) => {
  //     return api.provider().account.create('unique()', email, password, name);
  //   },

  getAccount: () => {
    return api.provider().account.get();
  },
  createTag: async(db, cl, tag) => {
    return api.provider().database.createDocument(db, cl, {value: tag});
  },

  login: (email, password) => {
    return api.provider().account.createEmailSession(email, password);
  },

  deleteCurrentSession: () => {
    return api.provider().account.deleteSession("current");
  },
  getTeamById: async (teamId) => {
    const provider = api.provider();
    const resp = await provider.team.get(teamId);
    const merge = await provider.database.listDocuments(Server.teamsDatabaseID, Server.teamRefID, [
      Query.equal("teamId", resp.$id),
    ]);
    return {...resp, ...merge.documents[0]}
  },

  getTeams: async (email) => {
    const provider = api.provider();
    const {
      documents: [{ teams }],
    } = await provider.database.listDocuments(Server.teamsDatabaseID, Server.userRefID, [
        Query.equal("email", email),
      ]);

    return await Promise.all(
      teams.map(async (team) => {
        return await provider.team.get(team);
      })
    );
  },
  deleteDocument: async (dbId, clId, docId) => {
    return await api.provider().database.deleteDocument(dbId, clId, docId);
  },
  
  joinTeams: async (teams) => {
    const provider = api.provider();
    const merged = teams.map(async (team) => {
      const {
        documents
      } = await provider
        .database.listDocuments(Server.teamsDatabaseID, Server.teamRefID, [
          Query.equal("teamId", team.$id),
        ]);
        return {...documents[0], ...team}
      })
      return await Promise.all(merged);

    
  },
  queryDocs: async (dbId, clId) => {
    const docs = await api.provider().database.listDocuments(dbId, clId);
    return docs.documents;
  },  
  createProject: async ({database, collection}, name, description) => {  
    const response = await api.provider().database.createDocument(database, collection, "unique()", {
      name,
      description,
      createdAt: new Date().toISOString(),
      modifiedAt: new Date().toISOString(),
    });
    return response;
  },
  register: async (email, password, name) => {
    const response = await api.provider().functions.createExecution(Server.registerFunctionID, JSON.stringify({
      email,
      password,
      name,
    }));
    return await api.login(email, password)
  },
  getSpecificProject: async (team, project) => {
    const teamDoc = await api.getTeamById(team);
    const projectDoc = await api.provider().database.getDocument(teamDoc.databaseId, teamDoc.projectsId, project);
    return projectDoc;
  },
  //   createDocument: (collectionId, data, read, write) => {
  //     return api
  //       .provider()
  //       .database.createDocument(collectionId, 'unique()', data, read, write);
  //   },

  //   listDocuments: (collectionId) => {
  //     return api.provider().database.listDocuments(collectionId);
  //   },

  //   updateDocument: (collectionId, documentId, data, read, write) => {
  //     return api
  //       .provider()
  //       .database.updateDocument(collectionId, documentId, data, read, write);
  //   },

  //   deleteDocument: (collectionId, documentId) => {
  //     return api.provider().database.deleteDocument(collectionId, documentId);
  //   },
};

export default api;
