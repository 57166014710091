import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import appwrite from '../../api/appwrite.js'

export const getAuth = createAsyncThunk(
    'auth/getAuth',
    async () => {
        return new Promise((resolve, reject) => {
            appwrite.getAccount().then(response => {
                console.log(response)
                resolve(response)
            }, (error) => {
                reject(error)
            })
        })
        
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        return new Promise((resolve, reject) => {
            appwrite.deleteCurrentSession().then(response => {
                console.log(response)
                resolve(response)
            }, (error) => {
                reject(error)
            })
        })
    }
)


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        loading: true,
        error: null,
        loggedIn: false,
        email: null,
    },
    reducers: {
        // login: (state, action) => {
        //     state.user = action.payload
        //     state.status = true
        // },
        // loginError: (state, action) => {
        //     state.error = action.payload
        //     state.status = false
        // },
    },
    extraReducers: {
        [getAuth.pending]: (state) => {
            state.loading = true
        },
        [getAuth.fulfilled]: (state, action) => {
            state.loading = false
            state.user = action.payload
            state.email = action.payload.email
            state.loggedIn = true
            state.error = null
        },
        [getAuth.rejected]: (state, action) => {
            state.loading = false
            state.error = action.error.message
            state.loggedIn = false
        },
        [logout.pending]: (state) => {
            state.loading = true
        },
        [logout.fulfilled]: (state, action) => {
            state.loading = false
            state.user = null
            state.loggedIn = false
            state.error = null
        },
        [logout.rejected]: (state, action) => {
            state.loading = false
            state.error = action.error.message
        }

}
})


// export const fetchUser = () => async (dispatch) => {
//     const response = await appwrite.getAccount()
//     if (response.status === 200) {
//         dispatch(login(response.data))
//     } else {
//         dispatch(loginError(response.error))
//     }
// }

// export const { logout } = authSlice.actions


export default authSlice.reducer