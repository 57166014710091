import { useLoaderData } from 'react-router-dom';
import { Typography } from '@mui/material';

export default function ProjectDirect() {
    const project = useLoaderData();
    return (
        <div>
            <Typography sx={{fontWeight: "bold"}} variant="h2">{project.name}</Typography>
        </div>
    )
}