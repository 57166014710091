import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import appwrite from "../../api/appwrite.js";

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (email) => {
    return new Promise((resolve, reject) => {
      appwrite.getTeams(email).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
);

export const assignUserProjects = createAsyncThunk(
  "projects/getAllProjects",
  async (arg, {getState}) => {
    const state = getState().projects;
    return new Promise((resolve, reject) => {
      appwrite.queryDocs(state.databaseId, state.projectsCollectionId).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    lastChecked: null,
    personalWorkspace: {},
    projects: [],
    databaseId: null,
    teams: [],
    projectsCollectionId: null,
    loading: true,
    error: null,
    tagsCollectionId: null,
    tags: [],
    tasksCollectionId: null,
    tasks: [],
  },
  reducers: {
    assignTeams: (state, action) => {
      const teams = action.payload
      state.teams = teams.filter(team => team.type !== 'user');
      const userWorkspace = teams.filter(team => team.type === 'user')[0];
      state.personalWorkspace = userWorkspace;
      const { databaseId, projectsId, tagsId, tasksId, } = userWorkspace;
      state.databaseId = databaseId;
      state.projectsCollectionId = projectsId;
      state.tagsCollectionId = tagsId;
      state.tasksCollectionId = tasksId;
      state.lastChecked = Date.now();
    },
  },
  extraReducers: {
    [getProjects.pending]: (state) => {
      state.loading = true;
    },
    [getProjects.fulfilled]: (state, action) => {
      state.loading = false;
      state.teams = action.payload;
    },
    [getProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [assignUserProjects.pending]: (state) => {
      state.loading = true;
    },
    [assignUserProjects.fulfilled]: (state, action) => {
      state.loading = false;
      state.projects = action.payload;
    },
    [assignUserProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { assignTeams } = projectsSlice.actions;

export default projectsSlice.reducer;
