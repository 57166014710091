import { useLoaderData } from 'react-router-dom';
import { Container } from '@mui/material'

export default function TeamDirect(props) {
    const data = useLoaderData();
    console.log(data)
    return (
        <Container>
            {JSON.stringify(data)}
        </Container>
    );
}