import { useEffect, useState } from 'react';
import {Typography} from '@mui/material'

export default function Projects(props) {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        const proj = [
            {
                name: "Project 1",
                description: "This is a project",
            },
            {
                name: "Project 2",
                description: "This is a project",
            },
            {
                name: "Project 3",
                description: "This is a project",
            },
        ]
        setProjects(proj)
    }, []);
    return projects.map((project, i) => <Typography variant="h6" sx={{fontSize: 18}} color="white" key={i}>{project.name}</Typography>)
}