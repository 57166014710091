import { useSelector } from 'react-redux';
import {Card, Typography, CardContent, CardActionArea} from '@mui/material';
import { useNavigate } from "react-router-dom"
import ProjectView from '../../components/projectsView';


function TeamsView() {
    const navigate = useNavigate()
    const teams = useSelector(state => state.projects.teams);
    const route = (link) => {
        navigate(link);
    }
    return (
        <>
            <h1>Teams</h1>
            {teams.map((team, i) => 
                (<Card key={`team-card-${i}`} >
                    <CardActionArea onClick={()=>route(`/teams/${team.$id}`)}>
                    <CardContent>
                        <Typography variant="h5" color="red" gutterBottom>
                            {team.name}
                        </Typography>
                        <ProjectView team={team} />
                        <br />
                        <Typography variant="p" gutterBottom>
                            Owned by {team.owner}
                        </Typography>
                    </CardContent>
                    </CardActionArea>
                </Card>)
            )}
        </>
    );
}

export default TeamsView;