import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import "../styles/login.css"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import {TextField} from '@mui/material';
import { Button } from '@mui/material';
import appwrite from '../api/appwrite'
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getAuth } from '../features/auth/authSlice';


function TabShell({ children, value, index }) {
    return (
        <div hidden={value !== index} className="tab-shell">
            {children}
        </div>
    )
}

function LoginForm() {
    const dispatch = useDispatch()
    const loggedIn = useSelector(state => state.loggedIn)
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn) {
        navigate("/")
        };
    }, [loggedIn, navigate]);
    
    const [emailError, setEmailError] = useState({value: "", message: "", invalid: false})
    const [passwordError, setPasswordError] = useState({value: "", message: "", invalid: false})
    const [loading, setLoading] = useState(false)
    const [loginError, setLoginError] = useState({value: false, message: ""})
    const [loginSuccess, setLoginSuccess] = useState(false)
    // useEffect(() => {
    //         if (loginSuccess||loggedIn) {
    //             navigate("/")
    //         };
    // }, [loggedIn, navigate, loading, loginSuccess, loginError]);
    useEffect(() => {
        dispatch(getAuth)
    }, [loading, dispatch]);
    
    const checkEmail = (event) => {
        const email = event.target.value
        if (email.length === 0) {
            setEmailError({invalid: true, message: "Email cannot be empty", value: email})
            return false
        }
        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setEmailError({invalid: true, message: "Email is invalid", value: email})
            return false
        }
        setEmailError({invalid: false, message: "", value: email})
        return true
    }

    const checkPassword = (event) => {
        const password = event.target.value
        if (password.length === 0) {
            setPasswordError({invalid: true, message: "Password cannot be empty", value: password})
            return false
        }
        if (password.length < 8) {
            setPasswordError({invalid: true, message: "Password must be at least 8 characters", value: password})
            return false
        }
        setPasswordError({invalid: false, message: "", value: password})
        return true
    }
    const onLogin = () => {
        if (checkEmail({target: {value: emailError.value}}) && checkPassword({target: {value: passwordError.value}})){
            const email = emailError.value
            const password = passwordError.value
        setLoading(true)
            appwrite.login(email, password).then((res) => {
                dispatch(getAuth())
                setLoginSuccess(true)
                setTimeout(() => {
                    navigate("/")
                    setLoading(false)
                }, 1000);
                
            }, (err) => {
                setLoginSuccess(false)
                setLoginError({value: true, message: "Error: invalid credentials"})
                setLoading(false)
                })
            }
        
        else {
            console.log("Login failed")
        }
    }
    if (loading) {
        return (
            <div>
        <CircularProgress  />
        </div>
        )
    }
    else {
        return (
          
            <div className="form">
                <p hidden={!loginError.value} className="errorMessage">{loginError.message}</p>
                <TextField error={emailError.invalid} helperText={emailError.message} sx={{width: "90%"}} onChange={checkEmail} required label="Email" variant="outlined" />
                <TextField type="password" error={passwordError.invalid} helperText={passwordError.message} sx={{width: "90%"}} onChange={checkPassword} required label="Password" variant="outlined" />
                <Button disabled={emailError.invalid || passwordError.invalid} sx={{width: "40%"}} onClick={onLogin} variant="contained">Login</Button>
                {/* <Button onClick={appwrite.deleteCurrentSession}>Logout</Button> */}
            </div>
           
        )
    }
   
}

const RegisterForm = () => {

    const navigate = useNavigate();

    const [emailError, setEmailError] = useState({value: "", message: "", invalid: false})
    const [passwordError, setPasswordError] = useState({value: "", message: "", invalid: false})
    const [secondPasswordError, setSecondPasswordError] = useState({value: "", message: "", invalid: false})
    const [nameError, setNameError] = useState({value: "", message: "", invalid: false})
    const [loading, setLoading] = useState(false)


    const checkEmail = (event) => {
        const email = event.target.value
        if (email.length === 0) {
            setEmailError({invalid: true, message: "Email cannot be empty", value: email})
            return false
        }
        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setEmailError({invalid: true, message: "Email is invalid", value: email})
            return false
        }
        setEmailError({invalid: false, message: "", value: email})
        return true
    }


    const checkPassword = (event) => {
        const password = event.target.value
        if (password.length === 0) {
            setPasswordError({invalid: true, message: "Password cannot be empty", value: password})
            return false
        }
        if (password.length < 8) {
            setPasswordError({invalid: true, message: "Password must be at least 8 characters", value: password})
            return false
        }
        setPasswordError({invalid: false, message: "", value: password})
        return true
    }

    const checkSecondPassword = (event) => {
        const password = event.target.value
        if (password !== passwordError.value) {
            setSecondPasswordError({invalid: true, message: "Passwords do not match", value: password})
        }
        else {
            setSecondPasswordError({invalid: false, message: "", value: password})
        }
    }

    const checkName = (event) => {
        const name = event.target.value
        if (name.length === 0) {
            setNameError({invalid: true, message: "Name cannot be empty", value: name})
            return false
        } 
        if (!name.includes(" ")) {
            setNameError({invalid: true, message: "Please enter a first and last name", value: name})
            return false
        }
        const destructuredName = name.split(" ") 
        if (destructuredName[0].length < 2 || destructuredName[1].length < 2) {
            setNameError({invalid: true, message: "Please enter a first and last name", value: name})
            return false
        }
        setNameError({invalid: false, message: "", value: name})
        return true
    }


    const onRegister = async() => {
        if (!secondPasswordError.invalid && !passwordError.invalid && !emailError.invalid) {
            setLoading(true)
            await appwrite.register(emailError.value, passwordError.value, nameError.value)
            setLoading(false)
            navigate("/")
        }
    }

    if (loading) {
        return (
            <div>
        <CircularProgress  />
        </div>
        )
    }
    else {
    return (
            <div className="form">
                <TextField error={nameError.invalid} helperText={nameError.message} sx={{width: "90%"}} onChange={checkName} required label="Full Name" variant="outlined" />
                <TextField error={emailError.invalid} helperText={emailError.message} sx={{width: "90%"}} onChange={checkEmail} required label="Email" variant="outlined" />
                <TextField type="password" error={passwordError.invalid} helperText={passwordError.message} sx={{width: "90%"}} onChange={checkPassword} required label="Password" variant="outlined" />
                <TextField type="password" error={secondPasswordError.invalid} helperText={secondPasswordError.message} sx={{width: "90%"}} onChange={checkSecondPassword} required label="Confirm Password" variant="outlined" />
                <Button disabled={emailError.invalid || passwordError.invalid} sx={{width: "40%"}} onClick={onRegister} variant="contained">Login</Button>
                {/* <Button onClick={appwrite.deleteCurrentSession}>Logout</Button> */}
            </div>
    )
    }
}

export default function Login() {
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };
    return (
    <div className="box">
            <div className="login-card">
            <Card sx={{ minWidth: 600}} className="login-card-item">
                <div style={{margin: "20px"}}>
                <h1 align="center">Projo</h1>
    
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Login" />
                    <Tab label="Register" />
                </Tabs>
                <TabShell value={tab} index={0}>
                    <LoginForm />
                </TabShell>
                <TabShell value={tab} index={1}>
                    <RegisterForm />
                </TabShell>
                </div>
            </Card>
            </div>
</div>
    )
}